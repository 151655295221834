<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <el-row style="margin-top: 50px">
        <el-col :span="23">
          <el-form
            :model="addForm"
            :rules="rules"
            size="small"
            label-width="120px"
          >
            <div>
              <el-row>
                <div style="line-height: 35px; margin-left: 35px">发布信息</div>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="发货公司" prop="sendOwnerId">
                    {{ addForm.sendOwner }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="联系人" prop="sendContacts">
                    {{ addForm.sendContacts }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="联系电话" prop="sendPhone">
                    {{ addForm.sendPhone }}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="发货地址" prop="sendAddrCode">
                    {{
                      addForm.sendProvince +
                      "/" +
                      addForm.sendCity +
                      "/" +
                      addForm.sendCounty
                    }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="详细地址" prop="sendAddressDetail">
                    {{ addForm.sendAddressDetail }}
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div>
              <el-row>
                <div style="line-height: 35px; margin-left: 35px">收货信息</div>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="发货公司" prop="receiveOwnerId">
                    {{ addForm.receiveOwner }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="联系人" prop="receiveContacts">
                    {{ addForm.receiveContacts }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="联系电话" prop="receivePhone">
                    {{ addForm.receivePhone }}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="发货地址" prop="receiveAddrCode">
                    {{
                      addForm.receiveProvince +
                      "/" +
                      addForm.receiveCity +
                      "/" +
                      addForm.receiveCounty
                    }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="详细地址" prop="receiveAddressDetail">
                    {{ addForm.receiveAddressDetail }}
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div>
              <el-row>
                <div style="line-height: 35px; margin-left: 35px">运输信息</div>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="车辆类型" prop="truckType">
                    {{ addForm.truckType }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="装货日期" prop="loadingDate">
                    {{ addForm.loadingDate }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="装车费" prop="loadingCharge">
                    {{ addForm.loadingCharge + "元/车" }}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="卸车费" prop="unloadingCharge">
                    {{ addForm.unloadingCharge + "(元/车)" }}
                  </el-form-item>
                </el-col>
                <el-col :span="16">
                  <el-form-item label="装卸要求" prop="loadRequirement">
                    {{ addForm.loadRequirement }}
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div>
              <el-row>
                <div style="line-height: 35px; margin-left: 35px">货物信息</div>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="货类" prop="cargoTypeId">
                    {{ addForm.cargoType }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="货名" prop="cargoTypeNameId">
                    {{ addForm.cargoTypeName }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="货物重量" prop="plantons">
                    {{ addForm.plantons + "吨" }}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="运费单价" prop="freightCharge">
                    {{ addForm.freightCharge + "元/吨" }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="委托人" prop="cargoAgent">
                    {{ addForm.cargoAgent }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="委托人电话" prop="cargoAgentPhone">
                    {{ addForm.cargoAgentPhone }}
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <el-row>
              <el-form-item label="发布去向">
                {{ addForm.isopen == 0 ? "货源大厅" : "司机/车队" }}
              </el-form-item>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
      <el-row style="margin-top: 50px">
        <el-col :offset="9">
          <template v-if="addForm.status == 11">
            <el-button
              size="mini"
              type="primary"
              class="buttons"
              @click="handlePass()"
              >通过</el-button
            >
            <el-button
              size="mini"
              type="danger"
              class="buttons"
              style="background: rgb(255, 89, 38); color: rgb(255, 255, 255)"
              @click="handleReject()"
              >驳回</el-button
            >
          </template>
          <el-button size="small" class="buttons" @click="goBack"
            >返回</el-button
          >
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import mTitle from "@/components/mTitle";
import areaSelect from "@/components/areaSelect.vue";
import treeSelect from "@/components/treeSelect.vue";
import { mapState } from "vuex";
export default {
  name: "particulars",
  data() {
    return {
      addForm: {
        sendOwner: "",
        sendContacts: "",
        sendPhone: "",
        sendProvince: "",
        sendCity: "",
        sendCounty: "",
        sendAddressDetail: "",
        receiveOwner: "",
        receiveContacts: "",
        receivePhone: "",
        receiveProvince: "",
        receiveCity: "",
        receiveCounty: "",
        receiveAddressDetail: "",
        truckType: "",
        loadingDate: "",
        loadingCharge: "",
        unloadingCharge: "",
        loadRequirement: "",
        cargoType: "",
        cargoTypeName: "",
        plantons: "",
        freightCharge: "",
        cargoAgent: "",
        cargoAgentPhone: "",
        isopen: 0,
        status: "",
      },
      ownerOptions: [],
      contactsOptions: [],
      dischargeOptions: [],
      truckTypeOptions: [],
      cargoTypeNameOptions: [],
      rules: {
        plate: [
          {
            required: true,
            message: "请输入车牌",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  components: {
    mTitle,
    areaSelect,
    treeSelect,
  },
  computed: {
    ...mapState(["pendingCount"]),
  },
  created() {
    if (this.$route.query.id) {
      this.$http(
        "api1",
        "/api/biz/PlanInfo/info/" + this.$route.query.id,
        "get"
      ).then((res) => {
        for (let key in this.addForm) {
          this.addForm[key] = res.data.data[key] || " - ";
        }
      });
    }
  },
  mounted() {},
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    handlePass() {
      this.$messageBox
        .confirm("确认" + "通过" + "该货源,是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(async () => {
          const { data } = await this.$http(
            "api1",
            "/api/biz/PlanInfo/auditAccept",
            "post",
            {
              planId: this.$route.query.id,
            }
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.goBack();
          }
        });
        this.getPendingCount();
    },
    handleReject() {
      this.$messageBox
        .prompt("请输入驳回原因", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
        .then(async ({ value }) => {
          const { data } = await this.$http(
            "api1",
            "/api/biz/PlanInfo/auditRefuse",
            "post",
            {
              fleetId: this.$route.query.id,
              ...this.addForm,
              refuseReason: value,
            }
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.goBack();
          }
          this.getPendingCount();
        })
        .catch(() => {});
    },
    async getPendingCount() {
      const res = await this.$http("api1", "/api/stat/pendingNum", "get");
      for (let item of this.pendingCount) {
        item.count = res.data[item.key];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
